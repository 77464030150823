.appt-book {
  #counters {
    @include breakpoint($landscape-down) {
      padding-top: 120px;
    }
  }
  .appt-book-page-header-content {
    @include breakpoint($landscape-down) {
      max-width: 60%;
    }
  }
  .service-select {
    .service {
      .service_tile-content {
        float: right;
      }
    }
  }
}
