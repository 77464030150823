.site-header {
  @include breakpoint($medium-up) {
    &.sticky {
      .site-header {
        &__menu {
          @include breakpoint($medium-landscape-only) {
            width: 65%;
          }
          .menu__item {
            &.menu__item--category {
              margin: 0 6px 0;
            }
            &--search {
              .icon--search {
                padding: 0 20px 0 10px;
              }
            }
          }
          &-wrapper {
            @include breakpoint($medium-landscape-only) {
              max-height: 67px;
            }
          }
        }
        &__utility-item {
          &.site-header-utility-not-signed-in {
            width: 95px;
          }
        }
        &__main {
          @include breakpoint($medium-landscape-only) {
            height: 70px;
          }
        }
      }
    }
    &__utility-item {
      &.site-header-utility-not-signed-in {
        width: 100px;
      }
    }
  }
}

.spp_reviews {
  .p-w-r {
    .pr-review-display {
      .pr-flag-review {
        padding-bottom: 10px;
        .pr-flag-review-btn {
          margin-bottom: 10px;
        }
      }
    }
    .pr-snippet {
      .pr-snippet-stars {
        &.pr-snippet-stars-png {
          .pr-snippet-rating-decimal {
            &:after {
              content: ' von 5';
            }
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Ja';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Nein';
              }
            }
          }
        }
      }
      .pr-rd-main-header {
        padding-bottom: 65px;
        padding-top: 0;
        @include breakpoint($large-up) {
          padding-top: 10px;
        }
        .pr-rd-main-header-search-sort {
          @include breakpoint($medium-up) {
            width: 55%;
          }
          .pr-rd-review-header-sorts {
            text-align: center;
            .pr-rd-sort-group {
              &:before {
                content: 'Sortieren nach :';
              }
            }
          }
          .pr-rd-main-header-search {
            top: 100px;
            @include breakpoint($med-small-up) {
              top: 80px;
            }
            .pr-rd-search-reviews-input {
              margin: 0 auto;
              float: none;
            }
          }
        }
      }
    }
  }
}

.pr-review-snapshot-snippets {
  .pr-snippet-review-count,
  .pr-snippet-stars-reco-reco {
    @include breakpoint($large-up) {
      width: 375px;
    }
  }
}

.product-full,
.product-quickshop {
  .product-inventory-status {
    &__item {
      display: none;
    }
  }
}

.waitlist-form {
  &__container {
    .waitlist-form {
      &__email {
        margin-bottom: 10px;
      }
    }
  }
  &__header {
    font-size: 30px;
    word-break: break-word;
    @include breakpoint($medium-up) {
      font-size: 55px;
    }
  }
}

.section-blue-heart {
  .site-content {
    .product-grid {
      &__add_all_to_bag {
        display: none;
      }
    }
  }
}

.ff-results {
  &-buttons {
    .ff-button {
      @include swap_direction(padding, 3px);
      line-height: normal;
      height: 4em;
      font-size: 11px;
      @include breakpoint($med-small-only) {
        font-size: 20px;
      }
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0);
        line-height: 2.7em;
        height: 3em;
        font-size: 16px;
      }
      &--back {
        @include swap_direction(padding, 10px);
        @include breakpoint($med-small-only) {
          @include swap_direction(padding, 20px);
        }
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0);
        }
      }
    }
  }
}

.site-footer {
  .sticky-add-to-bag {
    &__live-chat {
      &__disc {
        #{$ldirection}: 10px;
      }
    }
  }
  .social-media-links {
    @include breakpoint($medium-up) {
      padding-top: 20px;
    }
  }
}

.customer-service {
  @include breakpoint($medium-portrait-only) {
    &__menu {
      .menu {
        &__link {
          height: auto;
        }
      }
    }
    &__content {
      .cs-contact-info {
        width: 23%;
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .pr-header-required {
      display: block;
    }
  }
}

.spp_reviews {
  .p-w-r {
    .pr-review-display {
      .pr-flag-review {
        padding-bottom: 10px;
        .pr-flag-review-btn {
          margin-bottom: 10px;
        }
      }
    }
    .pr-snippet {
      .pr-snippet-stars {
        &.pr-snippet-stars-png {
          .pr-snippet-rating-decimal {
            &:after {
              content: ' von 5';
            }
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Ja';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Nein';
              }
            }
          }
        }
      }
      .pr-rd-main-header {
        padding-bottom: 65px;
        padding-top: 0;
        @include breakpoint($large-up) {
          padding-top: 10px;
        }
        .pr-rd-main-header-search-sort {
          .pr-rd-review-header-sorts {
            text-align: center;
            .pr-rd-sort-group {
              &:before {
                content: 'Sortieren nach :';
              }
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-block-container {
        .pr-review-snapshot-block-pros {
          .pr-review-snapshot-block-headline,
          .pr-review-snapshot-tags {
            display: none;
          }
        }
      }
    }
  }
}

.pr-review-snapshot-snippets {
  .pr-snippet-review-count,
  .pr-snippet-stars-reco-reco {
    @include breakpoint($large-up) {
      width: 375px;
    }
  }
}

.product-full,
.product-quickshop {
  .product-inventory-status {
    &__item {
      display: none;
    }
  }
}

.waitlist-form {
  &__container {
    .waitlist-form {
      &__email {
        margin-bottom: 10px;
      }
    }
  }
  &__header {
    font-size: 30px;
    word-break: break-word;
  }
}

.section-blue-heart {
  .site-content {
    .product-grid {
      &__add_all_to_bag {
        display: none;
      }
    }
  }
}

.site-footer {
  .livechat {
    &__text {
      display: block;
    }
    &_text {
      .text-beauty {
        @include swap_direction(padding, 3% 0 2% 5%);
        width: 100%;
        height: auto;
        margin-top: 15px;
      }
    }
  }
  .sticky-add-to-bag {
    &__live-chat {
      &__disc {
        #{$ldirection}: 10px;
      }
    }
  }
  .social-media-links {
    @include breakpoint($medium-up) {
      padding-top: 20px;
    }
  }
}

.ff-results {
  &-buttons {
    .ff-button {
      @include swap_direction(padding, 3px);
      line-height: normal;
      height: 4em;
      font-size: 11px;
      @include breakpoint($med-small-only) {
        font-size: 20px;
      }
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0);
        line-height: 2.7em;
        height: 3em;
        font-size: 16px;
      }
      &--back {
        @include swap_direction(padding, 10px);
        @include breakpoint($med-small-only) {
          @include swap_direction(padding, 20px);
        }
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0);
        }
      }
    }
  }
  &__recomended-shade {
    &:hover {
      .foundation-finder {
        &__results-product {
          &--quick-shop {
            display: none;
          }
        }
      }
    }
  }
}

.customer-service {
  @include breakpoint($medium-portrait-only) {
    &__menu {
      .menu {
        &__link {
          height: auto;
        }
      }
    }
    &__content {
      .cs-contact-info {
        width: 23%;
      }
    }
  }
}

.video-library-formatter {
  &__wrapper {
    @include breakpoint($medium-portrait-only) {
      background: $color-black;
    }
    .video-block {
      &__text {
        @include breakpoint($medium-portrait-only) {
          margin-bottom: 0;
        }
      }
    }
  }
  .slick-list {
    @include breakpoint($medium-portrait-only) {
      padding-#{$ldirection}: 8%;
    }
  }
}

.profile-page {
  &__content {
    .social-info {
      &__rstatus {
        input {
          &.connect-facebook-button {
            padding: 5px 10px;
            width: auto;
            @include breakpoint($medium-up) {
              width: 140px;
            }
          }
        }
      }
    }
  }
}

.site-email-signup {
  &__submit {
    order: 5;
  }
}

.product-brief {
  .product-price {
    &__prefix,
    &__label {
      text-transform: none;
    }
  }
}

#bt_settings {
  .bt-content {
    .slide-toggle {
      background: transparent url('/media/images/cookies/m_all-off_de.png');
      @include breakpoint($medium-up) {
        background: transparent url('/media/images/cookies/all-off_de.png') 100% 100% no-repeat;
      }
    }
    .mask {
      .slide-toggle {
        background-image: url('/media/images/cookies/m_all-on_de.png');
        @include breakpoint($medium-up) {
          background: transparent url('/media/images/cookies/all-on_de.png') 100% 100% no-repeat;
        }
      }
    }
  }
}

.product-full {
  &__rating {
    width: 80%;
    @include breakpoint($medium-up) {
      width: auto;
      float: #{$ldirection};
    }
    .review-snippet {
      .p-w-r {
        @include breakpoint($medium-up) {
          margin: 0;
        }
        .pr-snippet-stars-reco-inline {
          &.pr-snippet-compact,
          &.pr-snippet-minimal {
            .pr-snippet-read-and-write {
              width: 60%;
              @include breakpoint($medium-up) {
                width: auto;
              }
              a {
                &.pr-snippet-write-review-link {
                  padding: 0;
                  line-height: 1.05;
                  font-size: 14px !important;
                  @include breakpoint($medium-up) {
                    line-height: 1.45;
                    font-size: 15px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__accordion__container {
    @include breakpoint($medium-up) {
      margin-top: 35px;
    }
  }
}

.gnav-util {
  &--cart {
    .cart-item {
      &__title {
        display: none;
      }
    }
  }
}

.tooltipster-base {
  .tooltipster-content {
    text-transform: none;
  }
}

.section-chatprivacypolicy {
  .site-header,
  .footer {
    display: none;
  }
  .site-content {
    margin-top: 0;
    padding-top: 0;
    &__offer-banner {
      display: none;
    }
  }
}

.spp__container {
  .product-full {
    &.product-full-v1 {
      .sku-menu__option {
        @include breakpoint($medium-up) {
          margin-#{$ldirection}: 12px;
        }
      }
      .product-legal {
        padding: 15px 0 10px;
        @include breakpoint($medium-up) {
          padding-bottom: 0;
        }
      }
      .product-full__rating {
        width: auto;
        clear: none;
        margin-bottom: 10px;
        .review-snippet {
          .p-w-r {
            a.pr-snippet-write-review-link {
              @include breakpoint($medium-up) {
                font-size: 12px !important; /* stylelint-disable-line */
                margin-#{$rdirection}: 5px;
                margin-top: 2px;
              }
            }
            .pr-snippet-stars-reco-inline.pr-snippet-minimal {
              .pr-snippet-read-and-write {
                width: auto;
              }
            }
          }
        }
      }
      .product-sku-price {
        margin-#{$rdirection}: 5px;
      }
      .product-full__price-wrapper {
        margin-bottom: 0;
      }
      .product-full__content.bottom {
        .product-full__content-wrapper {
          .product-full__price-wrapper {
            display: block;
            @include breakpoint($medium-up) {
              display: flex;
            }
          }
        }
      }
      .product-full__sku-selector-container {
        width: 104%;
      }
      .sticky-add-to-bag {
        .selectBox {
          @include swap_direction(padding, 13px 20px 13px 0);
          height: auto;
          .sku-menu__option {
            line-height: 1;
            white-space: normal;
          }
        }
      }
    }
  }
}

.sku-menu-selectBox-dropdown-menu {
  li.sku-menu__option {
    a {
      white-space: normal;
      line-height: 1;
      padding: 10px 15px;
    }
  }
}

