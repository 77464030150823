.checkout {
  .checkout {
    &__content {
      .checkout-panel {
        &--shipping-edit-address {
          .address-form {
            .address3 {
              display: none;
            }
          }
        }
        .payment_frame {
          width: 100%;
          height: 32em;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
          @include breakpoint($large-up) {
            -webkit-overflow-scrolling: auto;
          }
          .gc_iframe {
            margin: 10px 0;
            width: 100%;
            height: 30em;
          }
        }
        &--order-summary {
          .value {
            @include breakpoint($medium-up) {
              width: auto;
            }
          }
          .order-summary {
            &__shipping-content {
              @include breakpoint($medium-up) {
                margin: 0;
                width: auto;
                clear: both;
              }
            }
          }
          .checkout-panel {
            &--viewcart {
              .link--secondary {
                &.edit {
                  display: inline-block;
                }
              }
            }
          }
        }
        &--shipmethod {
          text-align: $rdirection;
          .ship-method-group {
            span {
              float: #{$ldirection};
              width: 100%;
              padding-bottom: 10px;
              &:last-child {
                padding-bottom: 20px;
              }
              &.disabled_shipmethod {
                input[disabled] + label,
                input[type='radio'] ~ label::before {
                  cursor: not-allowed;
                  color: $color-light-gray;
                }
              }
            }
            .shipping {
              &__delivery-address {
                display: none;
              }
            }
          }
        }
        .payment-type {
          &.direct-debit {
            .direct-debit-nickname,
            .direct-debit-options {
              margin-bottom: 15px;
              .field {
                @include breakpoint($medium-up) {
                  width: 50%;
                }
              }
            }
          }
          img {
            height: 30px;
            width: auto;
          }
        }
        &--shipping {
          .view-address {
            .inline-title {
              @include breakpoint($medium-up) {
                border-bottom: none;
                border-top: 1px solid $color-light-gray;
              }
            }
            .saved-address {
              @include breakpoint($medium-up) {
                display: inline-block;
              }
            }
          }
        }
        .shipping-address-display-content {
          @include breakpoint($medium-up) {
            border-top: 1px solid $color-light-gray;
          }
        }
      }
    }
    .checkout {
      &__sidebar {
        .cart-items {
          .pc-hidden {
            @include breakpoint($medium-only) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.checkout--confirmation-page {
  .social-login-section {
    .social-login {
      &__terms {
        display: block;
        text-align: $ldirection;
        margin-bottom: 10px;
      }
    }
  }
}

#viewcart {
  .generic-helplinks-overlay {
    #cboxClose {
      #{$rdirection}: 15px;
    }
  }
  .checkout {
    &.viewcart {
      .checkout-panel {
        &--shipmethod {
          text-align: $ldirection;
          .ship-method-groups {
            display: block;
          }
        }
      }
    }
  }
}

.active-panel-review {
  section {
    .checkout-panel {
      .messages {
        padding: 0;
      }
      .payment-type-field {
        label {
          &.checkout-order {
            &__terms {
              float: #{$ldirection};
              margin-top: 10px;
              &::before {
                @include swap_direction(margin, 4px 8px 0 5px);
                @include breakpoint($medium-up) {
                  margin-top: 20px;
                }
              }
            }
          }
        }
        .payment-type {
          @include breakpoint($medium-up) {
            margin-top: 20px;
            border-top: 1px solid $color-black;
          }
          .radio {
            position: relative;
            @include breakpoint($medium-up) {
              padding: 25px 0;
            }
            &:nth-child(2) {
              font-size: 15px;
              border-bottom: 1px solid $color-dark-gray;
            }
            text-transform: uppercase;
            width: 100%;
            float: #{$rdirection};
            font-weight: bold;
            img {
              #{$rdirection}: 0;
              padding-#{$rdirection}: 3px;
              position: absolute;
              top: 11px;
              @include breakpoint($medium-up) {
                width: auto;
                height: 30px;
                padding: 0 10px;
                position: inherit;
              }
            }
            input[type='radio'] ~ label {
              &::before {
                @include swap_direction(margin, 0 6px 3px 5px);
              }
            }
          }
          .group2 {
            &:nth-child(3) {
              .radio {
                font-size: 15px;
                border-top: none;
                border-bottom: none;
              }
            }
            &.sofort {
              &:nth-child(4) {
                .radio {
                  font-size: 15px;
                  border-top: 1px solid $color-dark-gray;
                  width: 100%;
                  font-weight: bold;
                  float: $ldirection;
                  border-bottom: none;
                }
              }
            }
            &.sofort,
            &.bankeinzug {
              &:last-child {
                float: #{$ldirection};
                width: 100%;
                border-top: 1px solid $color-dark-gray;
                border-bottom: 1px solid $color-dark-gray;
                margin-bottom: 10px;
                .radio {
                  width: 100%;
                  border-top: none;
                  border-bottom: none;
                  border-#{$rdirection}: none;
                  font-size: 15px;
                  img {
                    @include breakpoint($medium-up) {
                      width: auto;
                      height: 30px;
                      padding: 0 10px;
                      float: none;
                    }
                  }
                  label {
                    font-weight: bold;
                    text-transform: uppercase;
                  }
                }
              }
            }
            .radio {
              label {
                img {
                  top: 11px;
                  @include breakpoint($medium-up) {
                    top: auto;
                  }
                }
              }
            }
          }
        }
        .direct-debit {
          display: inline-block;
          width: 100%;
          border: none;
          margin: 10px 0;
        }
        .form-submit {
          float: #{$rdirection};
        }
        .checkout {
          &__subtitle {
            border-bottom: none;
            margin: 0;
            padding: 0;
            text-align: center;
            @include breakpoint($medium-up) {
              text-align: $ldirection;
            }
            &__creditcard {
              @include swap_direction(padding, 15px 0 15px 15px);
              margin-top: 30px;
              font-size: 15px;
              font-weight: bold;
              text-transform: uppercase;
              border-top: 1px solid $color-dark-gray;
              border-bottom: 1px solid $color-light-gray;
              line-height: normal;
              font-family: $font--neue-haas-unica-pro;
              display: none;
            }
          }
        }
      }
    }
    .checkout {
      &__content {
        .checkout-panel {
          &.order-summary-content {
            border-bottom: none;
            .total-container {
              padding-top: 0;
            }
          }
          &.shipping-address-display-content,
          .sub-section {
            @include breakpoint($medium-up) {
              float: $ldirection;
              width: 100%;
              border-top: none;
            }
            .view-address {
              @include breakpoint($medium-up) {
                border-top: none;
              }
              h3 {
                @include breakpoint($medium-up) {
                  text-align: $ldirection;
                  padding-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

select {
  option[disabled] {
    cursor: not-allowed;
    color: $color-light-gray;
  }
}

.order-details-page {
  &__content {
    .order-info {
      .order-details {
        &__item-info {
          text-transform: none;
        }
      }
    }
  }
}

.active-panel-shipping {
  section {
    &.checkout-single-page {
      .sticky-checkout-button {
        position: static;
      }
    }
  }
}
