.appt-book {
  .location-submit {
    width: 310px;
  }
  .service-select {
    .service {
      .selection-bar {
        .selection-time {
          width: 40%;
        }
        .selection-selector {
          width: 60%;
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        overflow: visible;
        .registration__email-list-text {
          font-family: 'Neue Haas Unica Pro';
          font-size: 12px;
          font-variant: normal;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 18px;
        }
        .registration__email-list {
          height: 30px;
        }
      }
    }
  }
}
