.profile-page {
  .sms-promotions {
    display: none;
  }
}

.address-book-page {
  &__overlay {
    .address-form {
      .form-field {
        &.postalcode_state-field {
          input[type='text'] {
            width: 100%;
          }
          @include breakpoint($medium-up) {
            width: 48%;
            padding-#{$rdirection}: 8px;
            float: $ldirection;
          }
        }
      }
      .address3 {
        display: none;
      }
    }
  }
}

.account-profile {
  .section-content {
    .profile-info,
    .account-info {
      .account-profile {
        &__newsletter {
          .label {
            width: auto;
          }
          &-mobile {
            display: none;
          }
        }
      }
    }
  }
}

.orders-list {
  &__item {
    span {
      width: 56%;
      word-wrap: break-word;
    }
  }
  &__table {
    &--order {
      &-item-count {
        span {
          width: 65%;
          @include breakpoint($medium-portrait-only) {
            width: 56%;
          }
        }
      }
      @include breakpoint($large-up) {
        &-date {
          width: 16%;
        }
        &-number {
          width: 20%;
        }
      }
    }
  }
}

.social-login {
  &__container {
    .fb-login-button {
      z-index: 0;
    }
  }
}

.email-signup {
  &__header {
    padding: 10% 5% 0;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 5% 10% 0 12%);
    }
  }
  &__content {
    padding: 2% 5% 5%;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 1% 10% 3% 12%);
    }
  }
}
